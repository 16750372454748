<div class="spinner" *ngIf="isLoading">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>
<div class="container-fluid mt-3">
  <form [formGroup]="customerForm">
    <div class="sub-header d-flex  justify-content-end mr-5">
      <div class="Searchfields ">
        <form class="example">
          <div class="d-flex align-items-center" style="margin-top: 6px;">
            <div>
              <input type="text" placeholder="Search" name="search" class="input-search" (keyup)="applyFilter($event)">
            </div>
            <div>
              <button type="submit" class="serach-btn"><i class="fa fa-search"></i></button>
            </div>
          </div>
         
        
        </form>
      </div>
      <div class="fields">
        <mat-form-field appearance="outline">
          <mat-label>Select country</mat-label>
          <mat-select formControlName="select_country" panelClass="selectCountry">
            <mat-option *ngFor="let list of countryList" [value]="list.id">{{list.displayName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="fields">
        <mat-form-field appearance="outline">
          <mat-label>Package type</mat-label>
          <mat-select formControlName="package_type" (ngModelChange)="getTableData()" panelClass="selectPackageType">
            <mat-option value="all">All</mat-option>
            <mat-option *ngFor="let list of packageList" [value]="list._id">{{list.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="fields">
        <mat-form-field appearance="outline" *ngIf="downloadForActive">
          <mat-label>Download as</mat-label>
          <mat-select [class.selectButton]="exportSelected" [disabled]="disabledbutton"  panelClass="excel">
            <mat-option value="excel" (click)="downloadEXCEL()">EXCEL</mat-option>
         <!-- <mat-option value="pdf" (click)="savePDF()">PDF</mat-option> -->
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="downloadForNonActive">
          <mat-label>Download as</mat-label>
          <mat-select
          [class.selectButton]="exportSelected" [disabled]="disabledbutton">
            <mat-option value="excel" (click)="downloadEXCELNonActive()"> EXCEL</mat-option>
            <!-- <mat-option value="pdf" (click)="savePDF()">PDF</mat-option> -->
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="downloadForUnRegistered">
          <mat-label>Download as</mat-label>
          <mat-select
          [class.selectButton]="exportSelected" [disabled]="disabledbutton">
            <mat-option value="excel" (click)="downloadEXCELUnRegistered()"> EXCEL</mat-option>
            <!-- <mat-option value="pdf" (click)="savePDF()">PDF</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-4 mt-1 text-right">
        <form class="example">
          <input type="text" placeholder="Search" name="search" class="input-search" (keyup)="applyFilter($event)">
          <button type="submit" class="serach-btn"><i class="fa fa-search"></i></button>
        </form>
      </div>
      <div class="col-md-2 px-2">
        <mat-form-field appearance="outline">
          <mat-label>Select country</mat-label>
          <mat-select formControlName="select_country">
            <mat-option *ngFor="let list of countryList" [value]="list.id">{{list.displayName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 px-2">
        <mat-form-field appearance="outline">
          <mat-label>Package type</mat-label>
          <mat-select formControlName="package_type" (ngModelChange)="getTableData()">
            <mat-option value="all">All</mat-option>
            <mat-option *ngFor="let list of packageList" [value]="list._id">{{list.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 pl-0">
        <mat-form-field appearance="outline">
          <mat-label>Download as</mat-label>
          <mat-select
            [disabled]="currentTab==='active'?dataSourceActive.data.length===0:currentTab==='nonactive'?dataSourceNonActive.data.length===0:currentTab==='unregistered'?dataSourceUnRegistered.data.length===0:false">
            <mat-option value="excel" (click)="downloadEXCEL()">EXCEL</mat-option>
         // <mat-option value="pdf" (click)="savePDF()">PDF</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="downloadForNonActive">
          <mat-label>Download as</mat-label>
          <mat-select
            [disabled]="currentTab==='active'?dataSourceActive.data.length===0:currentTab==='nonactive'?dataSourceNonActive.data.length===0:currentTab==='unregistered'?dataSourceUnRegistered.data.length===0:false">
            <mat-option value="excel" (click)="downloadEXCELNonActive()">EXCEL</mat-option>
            //<mat-option value="pdf" (click)="savePDF()">PDF</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
  </form>
</div>
<div class="container-fluid mt-3">
  <div class="row my-0">
    <div class="col-md-12">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-active-tab" data-toggle="tab" href="#nav-active" role="tab"
            aria-controls="nav-active" aria-selected="true" (click)="[resetSelectedAll(),selectedTab('active')]" (click)="active()">Active
            Customer</a>
          <a class="nav-link" id="nav-non-active-tab" data-toggle="tab" href="#nav-non-active" role="tab"
            aria-controls="nav-non-active" aria-selected="false"
            (click)="[resetSelectedAll(),selectedTab('nonactive')]" (click)="nonActive()">Non-active
            Customer</a>
          <a class="nav-link" id="nav-non-unregistered-tab" data-toggle="tab" href="#nav-registered" role="tab"
            aria-controls="nav-registered" aria-selected="false"
            (click)="[resetSelectedAll(),selectedTab('unregistered')]" (click)="unRegistered()">Un Registered Customer</a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-active" role="tabpanel" aria-labelledby="nav-active-tab">
          <div class="container-fluid px-0">
            <mat-card>
              <ng-container>
                <div class="mat-elevation-z8">
                  <table mat-table [dataSource]="dataSourceActive" matSortActive="name" matSortDirection="asc" matSortDisableClear id="excel-table" matSort #activeSort="matSort"
                    class="customer-mat-table">
                    <!--Check box-->
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox class="mt-2" disableRipple="true" (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected(dataSourceActive)" #headerChecked
                          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
                          (change)="getCheckboxValuesAllEmployee($event,dataSourceActive.filteredData)">
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox #dataChecked class="mt-2" disableRipple="true" (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)" (change)="checkboxValuesEmployeeRow($event,row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>

                    <!--Customer Name Column -->
                    <ng-container matColumnDef="customerName" >
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
                      <td mat-cell *matCellDef="let row" class="pointer" (click)="customerDashboard(row)">{{ row.customerName }}</td>
                    </ng-container>

                    <!-- Purchase date Column -->
                    <ng-container matColumnDef="purchaseDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase date</th>
                      <td mat-cell *matCellDef="let row">{{ row.purchaseDate|date:'dd MMM yyyy'}}</td>
                    </ng-container>

                    <!-- Package type Column -->
                    <ng-container matColumnDef="packageType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Package type</th>
                      <td mat-cell *matCellDef="let row">{{ row.packageType }}</td>
                    </ng-container>

                    <!-- Duration Column -->
                    <ng-container matColumnDef="duration">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
                      <td mat-cell *matCellDef="let row">{{ row.duration }}</td>
                    </ng-container>

                    <!-- Pack expiration date Column -->
                    <ng-container matColumnDef="packExpiryDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack expiration date</th>
                      <td mat-cell *matCellDef="let row">{{ row.packExpiryDate | date:'dd MMM yyyy'}}</td>
                    </ng-container>

                    <!-- Total earnings Column -->
                    <ng-container matColumnDef="totalEarnings">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Total earnings</th>
                      <td mat-cell *matCellDef="let row">{{ row.totalEarnings }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                  <mat-error *ngIf="
                      dataSourceActive.data.length == 0 ||
                      (dataSourceActive.filteredData.length == 0 &&
                        isLoading == false &&
                        errorMsg)
                    " class="errorMsg text-center mt-3">{{ errorMsg }}</mat-error>
                 
                  <mat-paginator #activePaginator [pageSizeOptions]="[5,10,20,50,100]"></mat-paginator>
                </div>
              </ng-container>
            </mat-card>
          </div>
        </div>

        <div class="tab-pane fade" id="nav-non-active" role="tabpanel" aria-labelledby="nav-non-active-tab">
          <div class="container-fluid px-0">
            <mat-card>
              <ng-container>
                <div class="mat-elevation-z8">
                  <table mat-table [dataSource]="dataSourceNonActive" id="excel-table" #nonActiveSort="matSort"  matSortActive="customerName" matSortDirection="asc" matSortDisableClear matSort
                    class="customer-mat-table">

                    <!--Check box-->
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox class="mt-2" disableRipple="true" (change)="$event ? masterToggle1() : null"
                          [checked]="selection1.hasValue() && isAllSelected1(dataSourceNonActive)" #headerChecked1
                          [indeterminate]="selection1.hasValue() && !isAllSelected1()" [aria-label]="checkboxLabel1()"
                          (change)="getCheckboxValuesAllEmployeeNonActive($event,dataSourceNonActive.filteredData)">
                        </mat-checkbox>

                        <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox> -->
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox #dataChecked1 class="mt-2" disableRipple="true" (click)="$event.stopPropagation()"
                          (change)="$event ? selection1.toggle(row) : null" [checked]="selection1.isSelected(row)"
                          [aria-label]="checkboxLabel1(row)" (change)="checkboxValuesEmployeeRowNonActive($event,row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>

                    <!--Customer Name Column -->
                    <ng-container matColumnDef="customerName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
                      <td mat-cell *matCellDef="let row">{{ row.customerName }}</td>
                    </ng-container>

                    <!-- Purchase date Column -->
                    <ng-container matColumnDef="purchaseDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase date</th>
                      <td mat-cell *matCellDef="let row">{{ row.purchaseDate |date:'dd MMM yyyy'}}</td>
                    </ng-container>

                    <!-- Package type Column -->
                    <ng-container matColumnDef="packageType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Package type </th>
                      <td mat-cell *matCellDef="let row">{{ row.packageType }}</td>
                    </ng-container>

                    <!-- Duration Column -->
                    <ng-container matColumnDef="duration">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
                      <td mat-cell *matCellDef="let row">{{ row.duration }}</td>
                    </ng-container>

                    <!-- Pack expiration date Column -->
                    <ng-container matColumnDef="packExpiryDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack expiration date</th>
                      <td mat-cell *matCellDef="let row">{{ row.packExpiryDate |date:'dd MMM yyyy'}}</td>
                    </ng-container>

                    <!-- Total earnings Column -->
                    <ng-container matColumnDef="totalEarnings">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Total earnings</th>
                      <td mat-cell *matCellDef="let row">{{ row.totalEarnings }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                  <mat-error *ngIf="
                      dataSourceNonActive.data.length == 0 ||
                      (dataSourceNonActive.filteredData.length == 0 &&
                        isLoading == false &&
                        errorMsg)
                    " class="errorMsg text-center mt-3">{{ errorMsg }}</mat-error>
                  <div class="spinner" *ngIf="isLoading">
                    <mat-spinner [diameter]="50"></mat-spinner>
                  </div>
                  <mat-paginator #nonactivePaginator [pageSizeOptions]="[5,10,20,50,100]"></mat-paginator>
                </div>
              </ng-container>
            </mat-card>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-registered" role="tabpanel" aria-labelledby="nav-non-unregistered-tab">
          <div class="container-fluid px-0">
            <mat-card>
              <ng-container>
                <div class="mat-elevation-z8">
                  <table mat-table [dataSource]="dataSourceUnRegistered" id="excel-table" matSort
                    #nonActiveSort="matSort" class="customer-mat-table">
                    <!--Check box-->
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox class="mt-2" disableRipple="true" (change)="$event ? masterToggle2() : null"
                          [checked]="selection2.hasValue() && isAllSelected2(dataSourceUnRegistered)" #headerChecked2
                          [indeterminate]="selection2.hasValue() && !isAllSelected2()" [aria-label]="checkboxLabel2()"
                          (change)="getCheckboxUnRegisteredValuesAllEmployee($event,dataSourceUnRegistered.filteredData)">
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox #dataChecked2 class="mt-2" disableRipple="true" (click)="$event.stopPropagation()"
                          (change)="$event ? selection2.toggle(row) : null" [checked]="selection2.isSelected(row)"
                          [aria-label]="checkboxLabel2(row)" (change)="checkboxValuesEmployeeRowUnRegistered($event,row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>

                    <!--Customer Name Column -->
                    <ng-container matColumnDef="customerName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
                      <td mat-cell *matCellDef="let row">{{ row.customerName }}</td>
                    </ng-container>

                    <!-- Purchase date Column -->
                    <ng-container matColumnDef="purchaseDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase date</th>
                      <td mat-cell *matCellDef="let row">{{ row.purchaseDate|date:'dd MMM yyyy'}}</td>
                    </ng-container>

                    <!-- Package type Column -->
                    <ng-container matColumnDef="packageType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Package type</th>
                      <td mat-cell *matCellDef="let row">{{ row.packageType }}</td>
                    </ng-container>

                    <!-- Duration Column -->
                    <ng-container matColumnDef="duration">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
                      <td mat-cell *matCellDef="let row">{{ row.duration }}</td>
                    </ng-container>

                    <!-- Pack expiration date Column -->
                    <ng-container matColumnDef="packExpiryDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack expiration date</th>
                      <td mat-cell *matCellDef="let row">{{ row.packExpiryDate | date:'dd MMM yyyy'}}</td>
                    </ng-container>

                    <!-- Total earnings Column -->
                    <ng-container matColumnDef="totalEarnings">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Total earnings</th>
                      <td mat-cell *matCellDef="let row">{{ row.totalEarnings }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                  <mat-error *ngIf="
                      dataSourceUnRegistered.data.length == 0 ||
                      (dataSourceUnRegistered.filteredData.length == 0 &&
                        isLoading == false &&
                        errorMsg)
                    " class="errorMsg text-center mt-3">{{ errorMsg }}</mat-error>
                  <div class="spinner" *ngIf="isLoading">
                    <mat-spinner [diameter]="50"></mat-spinner>
                  </div>
                  <mat-paginator #unRegisteredPaginator [pageSizeOptions]="[5,10,20,50,100]"></mat-paginator>
                </div>
              </ng-container>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinner" *ngIf="isLoading">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>
<div class="container-fluid mt-3">
  <form [formGroup]="revenueForm" class="mr-5">
    <div class="row">
      <div class="col-md-3">
        <label class="font-weight-bold" style="font-size: 16px;">Today's total revenue :</label>
        <span class="amount-text ml-2">{{this.totalRevenue?.toLocaleString('en-IN')?this.totalRevenue:'0'}}/-</span>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 mt-1 text-right">
        <form class="example">
          <input type="text" placeholder="Search" (keyup)="applyFilter($event)" name="search" class="input-search">
          <button type="submit" class="serach-btn"><i class="fa fa-search"></i></button>
        </form>
      </div>
      <div class="col-md-1 pr-0">
        <mat-form-field appearance="outline">
          <mat-label>Today</mat-label>
          <mat-select formControlName="day" (ngModelChange)="getTableData()">
            <!-- <mat-option value="">--empty--</mat-option> -->
            <mat-option *ngFor="let day of days" [value]="day.display_value">{{day.display_name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-1 px-2">
        <mat-form-field appearance="outline">
          <mat-label>Month</mat-label>
          <mat-select formControlName="months" (ngModelChange)="getTableData()">
            <!-- <mat-option value=""></mat-option> -->
            <mat-option *ngFor="let month of months" [value]="month">{{month}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-1 pl-0">
        <mat-form-field appearance="outline">
          <mat-label>Year</mat-label>
          <mat-select formControlName="years" (ngModelChange)="getTableData()">
            <!-- <mat-option value=""></mat-option> -->
            <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="container-fluid">
  <mat-card>
    <ng-container>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSortActive="customerName" matSortDirection="asc" matSortDisableClear  matSort class="customer-mat-table">

          <!--Check box-->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox class="mt-2" disableRipple="true" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox class="mt-2" disableRipple="true" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!--Customer Name Column -->
          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
            <td mat-cell *matCellDef="let row">{{ row.customerName }}</td>
          </ng-container>

          <!-- Purchase date Column -->
          <ng-container matColumnDef="purchaseDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase date</th>
            <td mat-cell *matCellDef="let row">{{ row.purchaseDate | date:'dd MMM yyyy' }}</td>
          </ng-container>

          <!-- Package type Column -->
          <ng-container matColumnDef="packageType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Package type</th>
            <td mat-cell *matCellDef="let row">{{ row.packageType }}</td>
          </ng-container>

          <!-- Duration Column -->
          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
            <td mat-cell *matCellDef="let row">{{ row.duration }}</td>
          </ng-container>

          <!-- Pack expiration date Column -->
          <ng-container matColumnDef="packExpiryDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack expiration date</th>
            <td mat-cell *matCellDef="let row">{{ row.packExpiryDate |date:'dd MMM yyyy' }}</td>
          </ng-container>

          <!-- Total earnings Column -->
          <ng-container matColumnDef="totalEarnings">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total earnings</th>
            <td mat-cell *matCellDef="let row">{{ row.totalEarnings }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-error *ngIf="
            dataSource.data.length == 0 ||
            (dataSource.filteredData.length == 0 &&
              isLoading == false &&
              errorMsg)
          " class="errorMsg text-center mt-3">{{ errorMsg }}</mat-error>
       
        <mat-paginator [pageSizeOptions]="[5,10,20,50,100]"></mat-paginator>
      </div>
    </ng-container>
  </mat-card>
</div>

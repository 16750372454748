<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <div class="navbar-nav mr-auto">
    <h2 class="mb-0">{{userDisplayName}}</h2>
  </div>
  <form class="form-inline my-2 my-lg-0">
    <ul class="navbar-nav">
      <li class="nav-item" style="cursor: pointer;" matTooltip="notify" matTooltipPosition="below">
        <a class="nav-link" routerLink="/reminder"><i class="fa fa-bell-o" aria-hidden="true"></i></a>
      </li>
      <li class="nav-item mr-3" (click)="logOut()" style="cursor: pointer;" matTooltip="Logout"
        matTooltipPosition="below">
        <a class="nav-link"><i class="fas fa-sign-out-alt"></i></a>
      </li>
      <li class="nav-item" style="cursor: pointer;" matTooltip="user" matTooltipPosition="below">
        <div class="profile-img mr-1">
          <img src='' onerror="this.src='./assets/images/logo.png'" alt="character">
        </div>
      </li>
    </ul>
  </form>
</nav>

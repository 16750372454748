
<mat-drawer-container class="example-container">
  <mat-drawer  mode="side" [opened]="sideBarOpen">
    <app-navbar></app-navbar>
  </mat-drawer>
  <mat-drawer-content >
    <app-header  (toggleSidebarForMe)="sideBarToggler()"></app-header>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
